@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  color: #fff;
  scroll-behavior: smooth;
  font-family: ubuntu, Helvetica, sans-serif;
  font-size: 10px;
}

body {
  background-color: #111;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
}

@media (max-width: 512px) {
  body {
    font-size: 1rem;
  }
}

h1 {
  font-family: Oswald, sans-serif;
  font-size: 2.4rem;
}

h2 {
  min-height: 100px;
  font-family: Montserrat, sans-serif;
  font-size: 4rem;
}

@media (max-width: 512px) {
  h2 {
    min-height: 80px;
    font-size: 3rem;
  }
}

h3 {
  font-family: Montserrat, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
}

h4 {
  font-family: Montserrat, sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

section {
  min-height: 100vh;
  padding: 120px 10% 0;
}

section#hero {
  min-height: 100vh;
  padding: 0;
}

@media (max-width: 1020px) {
  section {
    min-height: 90vh;
  }
}

fieldset {
  width: 100%;
  border: none;
}

input, textarea {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  transition: color .5s, border-color .5s;
}

.bold {
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
}

.btn {
  letter-spacing: 1px;
  color: #ddd;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ddd;
  border-radius: 15px;
  outline: none;
  padding: 15px 30px;
  font-family: Kanit, sans-serif;
  font-size: 1.3rem;
  transition: all .5s;
}

.contact-link {
  color: #777;
  font-size: 1.6rem;
  text-decoration: none;
}

.contact-link:hover {
  color: #dd1313;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

header {
  z-index: 5;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  padding: 0 20px;
  transition: all 1s;
  display: flex;
  position: fixed;
}

header.scrolled {
  height: 85px;
  background-color: #32323280;
}

header.scrolled .logo {
  filter: drop-shadow(2px 2px 2px #000);
  font-size: 2rem;
}

header.scrolled .logo-pic {
  transform: scale(.8);
}

header.scrolled .hamburger {
  top: 20px;
}

.nav {
  color: #aaa;
  font-size: 1.8rem;
}

@media (max-width: 1020px) {
  .nav {
    height: 0;
    background-color: #000;
    transition: all .5s;
    position: absolute;
    top: 10px;
    right: 20px;
    overflow: hidden;
  }

  .nav.open {
    height: 520px;
  }
}

.nav-list {
  height: 100%;
  list-style: none;
  display: flex;
}

@media (max-width: 1020px) {
  .nav-list {
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 70px 50px 100px;
  }
}

.nav-item {
  align-items: center;
  margin-right: 50px;
  display: flex;
}

@media (max-width: 1020px) {
  .nav-item {
    margin-right: 0;
  }
}

.nav-item.active {
  color: #ddd;
  font-weight: 600;
}

.nav-link {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 1020px) {
  .nav-link {
    width: 100%;
    text-align: right;
  }
}

.nav-link:hover {
  color: #dd1313;
}

.logo {
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  transition: font-size 1s;
  display: flex;
}

.logo-wrapper {
  width: 55px;
  height: 55px;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 50%;
  margin: 20px 10px 20px 0;
  font-size: 3rem;
  line-height: 50px;
  transition: transform 1s;
  transform: scale(1);
}

.logo-pic {
  height: 50px;
  margin-right: 10px;
  transition: all .5s;
  transform: scale(1);
}

.hamburger {
  height: 50px;
  width: 50px;
  flex-direction: column;
  align-items: flex-end;
  transition: top 1s;
  display: none;
  position: absolute;
  top: 27px;
  right: 24px;
}

@media (max-width: 1020px) {
  .hamburger {
    cursor: pointer;
    display: flex;
  }
}

.hamburger:hover .hamburger-item {
  background-color: #dd1313;
}

.hamburger-item {
  height: 4px;
  transform: translate(0), rotate(0);
  transform-origin: center;
  opacity: 1;
  background-color: #ddd;
  transition: all .5s;
  position: absolute;
  box-shadow: 1px 1px 3px #000;
}

.hamburger-item:first-child {
  width: 50px;
  top: 0;
}

.hamburger-item:nth-child(2) {
  width: 20px;
  top: 20px;
}

.hamburger-item:nth-child(3) {
  width: 30px;
  top: 40px;
}

.hamburger.open .hamburger-item {
  box-shadow: none;
}

.hamburger.open .hamburger-item:first-child {
  transform: rotate(45deg)translate(25%, 15px);
}

.hamburger.open .hamburger-item:nth-child(2) {
  opacity: 0;
  transform: translateX(-30px);
}

.hamburger.open .hamburger-item:nth-child(3) {
  width: 50px;
  transform: rotate(-45deg)translate(25%, -15px);
}

.hero {
  align-items: stretch;
  display: flex;
  box-shadow: inset 1px -30px 30px #111;
}

.hero-item {
  width: 50%;
  max-width: 50%;
}

.hero .title {
  text-align: center;
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

@media (max-width: 1020px) {
  .hero .title {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
}

@media (min-width: 1021px) and (orientation: portrait) {
  .hero .title {
    width: 100%;
    max-width: 100%;
    align-items: center;
    padding-bottom: 40%;
  }
}

.hero .title h1 {
  margin: 2rem 0;
  font-family: Oswald, sans-serif;
  font-size: 7rem;
}

@media (max-width: 512px) {
  .hero .title h1 {
    margin: 10rem 0 4rem;
    font-size: 4rem;
  }
}

.hero .title p {
  margin: 4rem 0;
}

.hero .title-subtitle {
  margin: 4rem 0;
  font-size: 1.8rem;
}

.hero .title-subtitle #cursor {
  opacity: 0;
}

.hero .title-subtitle #cursor.hidden {
  opacity: 1;
}

.hero .title-btns {
  padding: 0 15% 0 20%;
}

@media (max-width: 512px) {
  .hero .title-btns {
    padding: 0;
  }
}

.hero .title-btn:nth-child(1) {
  color: #111;
  background-color: #ddd;
  border: none;
  margin-right: 3rem;
  font-weight: 600;
}

@media (max-width: 512px) {
  .hero .title-btn:nth-child(1) {
    margin-right: 1rem;
  }
}

.hero .title-btn:nth-child(1):hover, .hero .title-btn:nth-child(1):focus {
  background-color: #dd1313;
  border-color: #dd1313;
  box-shadow: 2px 2px 5px #000;
}

.hero .title-btn:nth-child(2) {
  color: #ddd;
  border: none;
  font-weight: 600;
}

.hero .title-btn:nth-child(2):hover, .hero .title-btn:nth-child(2):focus {
  color: #dd1313;
}

.hero .title-icons {
  justify-content: space-around;
  padding: 0 20%;
  font-size: 2rem;
  display: flex;
}

.hero .title-social-icon {
  color: #ddd;
}

.hero .title-social-icon:hover {
  color: #dd1313;
}

.hero .photo {
  position: relative;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .hero .photo {
    width: 0;
  }
}

@media (max-width: 1020px) {
  .hero .photo {
    display: none;
  }
}

.hero .photo img {
  height: 100%;
  max-height: 600px;
  z-index: -1;
  filter: brightness(90%);
  position: absolute;
  bottom: 0;
  right: 0;
}

.about {
  --height-img: 80vh;
  display: flex;
  position: relative;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .about {
    --height-img: 600px;
    min-height: auto;
  }
}

@media (max-width: 1020px) {
  .about {
    --height-img: 400px;
    min-height: 80vh;
    --height-img: 400px;
    flex-direction: column;
  }
}

@media (max-width: 512px) {
  .about {
    --height-img: 250px;
  }
}

.about-photo {
  width: calc(var(--height-img) * .75);
  height: calc(var(--height-img));
  max-width: 40vw;
  background-image: url("bike_fast.3e3293b7.jpg");
  background-position: 100% 0;
  background-size: cover;
  border-radius: 20px;
  flex-basis: auto;
  margin: 0 30px 0 0;
  position: relative;
  box-shadow: 12px 12px 12px #000000b3, 0 0 30px #00000080, 0 0 45px #0003;
}

@media (max-width: 1020px) {
  .about-photo {
    position: absolute;
    top: 120px;
    right: 0;
  }
}

@media (max-width: 720px) {
  .about-photo {
    position: absolute;
    top: 120px;
    right: 0;
  }
}

@media (max-width: 512px) {
  .about-photo {
    z-index: -1;
    top: 115px;
  }
}

.about-content {
  flex-wrap: wrap;
  flex-shrink: 50;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 512px) {
  .about-content {
    padding-top: 0;
  }
}

.about-title {
  flex-basis: 100%;
  font-size: 4rem;
}

@media (max-width: 512px) {
  .about-title {
    margin-bottom: calc(var(--height-img)  - 65px);
  }
}

.about-text {
  flex-basis: 60%;
}

@media (max-width: 1020px) {
  .about-text {
    flex-basis: 55%;
  }
}

@media (max-width: 512px) {
  .about-text {
    flex-basis: 100%;
  }
}

.about-paragraph {
  margin-bottom: 10px;
}

.about-coop {
  flex-basis: 35%;
}

@media (max-width: 1020px) {
  .about-coop {
    margin-top: calc(var(--height-img)  - 50px);
  }
}

@media (max-width: 512px) {
  .about-coop {
    flex-basis: 100%;
    margin-top: 20px;
  }
}

.about-coop-title {
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 400;
}

.about-coop-link {
  margin-top: 2rem;
  display: block;
}

.about-codete-link {
  color: red;
}

.about-codete-link:visited {
  color: red;
}

.about-codete-link:hover {
  text-shadow: 1px 1px 5px red;
}

.skills {
  position: relative;
}

.skills-desc {
  max-width: 40%;
}

@media (max-width: 512px) {
  .skills-desc {
    max-width: 100%;
  }
}

.skills-title {
  flex-basis: 100%;
}

.skills-text {
  flex-basis: 35%;
}

.skills-menu {
  z-index: 4;
  list-style: none;
  position: absolute;
  top: 185px;
  right: 10%;
}

@media (max-width: 512px) {
  .skills-menu {
    flex-wrap: wrap;
    display: flex;
    position: static;
  }
}

.skills-menu-item {
  text-align: right;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 50px;
  padding: 10px 20px;
  font-family: Kanit, sans-serif;
  font-size: 2rem;
}

@media (max-width: 1020px) {
  .skills-menu-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 512px) {
  .skills-menu-item {
    min-width: auto;
    flex-basis: 50%;
    margin-top: 20px;
    font-size: 1.6rem;
  }

  .skills-menu-item:nth-child(2n+1) {
    text-align: left;
  }
}

.skills-menu-item.active {
  color: #111;
  background-color: #ddd;
  border-radius: 20px;
  transition: background-color .5s;
}

.skills-menu-item:hover {
  color: #dd1313;
}

.skills-display {
  position: relative;
}

.skills-display .measure {
  opacity: 0;
  color: #333;
  width: 100%;
  height: 40px;
  padding-top: 30px;
  transition: opacity 1s;
  display: flex;
}

.skills-display .measure.visible {
  opacity: 1;
}

.skills-display .measure-unit {
  text-align: right;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  flex-basis: 20%;
  padding-right: 5px;
}

@media (max-width: 512px) {
  .skills-display .measure-unit {
    flex-basis: 31%;
  }

  .skills-display .measure-unit:last-child {
    display: none;
  }
}

.skills-display .measure-unit:first-child {
  padding-left: 20px;
}

.skill {
  opacity: 0;
  padding: 40px 20px 20px;
  position: absolute;
  inset: 0;
}

.skill.active {
  opacity: 1;
  z-index: 3;
  position: static;
}

.skill.active .skill-one.new-stack {
  margin-top: 50px;
}

@media (max-width: 512px) {
  .skill.active .skill-one.new-stack:first-child {
    margin-top: 10px;
  }
}

.skill.active .skill-one.amazing .skill-progress {
  width: 80%;
  background-image: linear-gradient(90deg, #111, #92f592);
}

@media (max-width: 512px) {
  .skill.active .skill-one.amazing .skill-progress {
    width: 99%;
  }
}

.skill.active .skill-one.very-good .skill-progress {
  width: 60%;
  background-image: linear-gradient(90deg, #111, #0f0);
}

@media (max-width: 512px) {
  .skill.active .skill-one.very-good .skill-progress {
    width: 99%;
  }
}

.skill.active .skill-one.good .skill-progress {
  width: 40%;
  background-image: linear-gradient(90deg, #111, #ff0);
}

@media (max-width: 512px) {
  .skill.active .skill-one.good .skill-progress {
    width: 66%;
  }
}

.skill.active .skill-one.basic .skill-progress {
  width: 20%;
  background-image: linear-gradient(90deg, #111, orange);
}

@media (max-width: 512px) {
  .skill.active .skill-one.basic .skill-progress {
    width: 33%;
  }
}

.skill-none {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  font-size: 5rem;
  display: flex;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .skill-none {
    margin-top: 30vh;
  }
}

@media (max-width: 1020px) {
  .skill-none {
    margin-top: 10vh;
  }
}

@media (max-width: 512px) {
  .skill-none {
    font-size: 3rem;
  }
}

.skill-none .text {
  padding-right: 20px;
  position: relative;
}

.skill-none .text-bg {
  z-index: -1;
  color: #555;
  font-size: 8rem;
}

@media (max-width: 512px) {
  .skill-none .text-bg {
    font-size: 6rem;
  }
}

.skill-none .text-front {
  position: absolute;
  bottom: 0;
  right: 0;
}

.skill-name {
  color: #888;
  margin-top: 15px;
  font-size: 1.8rem;
}

.skill-bar {
  position: relative;
  bottom: 5px;
}

.skill-progress {
  height: 5px;
  width: 1px;
  border-radius: 0 3px 3px 0;
  transition: all .5s;
  display: block;
}

@keyframes slide {
  0% {
    left: 100%;
    right: -50%;
  }

  50% {
    left: 0;
    right: 0;
  }

  100% {
    left: -50%;
    right: 100%;
  }
}

@keyframes slide-back {
  0% {
    left: -50%;
    right: 100%;
  }

  50% {
    left: 0;
    right: 0;
  }

  100% {
    left: 100%;
    right: -50%;
  }
}

@keyframes blink {
  1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes back-move {
  0% {
    left: 70%;
  }

  100% {
    left: 10%;
  }
}

.projects {
  min-height: 95vh;
  padding-top: 100px;
  display: flex;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .projects {
    min-height: 70vh;
  }
}

@media (max-width: 1020px) {
  .projects {
    min-height: 70vh;
  }
}

@media (max-width: 720px) {
  .projects {
    flex-direction: column;
    position: relative;
  }
}

.projects-section {
  flex-basis: 50%;
  position: relative;
}

@media (max-width: 720px) {
  .projects-section {
    flex-basis: auto;
    padding: 0;
  }
}

.projects-section-left {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 15px;
  display: flex;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .projects-section-left {
    flex-basis: 40%;
  }
}

@media (max-width: 1020px) {
  .projects-section-left {
    flex-basis: 40%;
  }
}

@media (max-width: 720px) {
  .projects-section-left {
    padding: 0;
  }
}

.projects-section-right {
  padding: 100px 0 30px 15px;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .projects-section-right {
    flex-basis: 60%;
    padding-top: 0;
    font-size: 1.2rem;
  }
}

@media (max-width: 1020px) {
  .projects-section-right {
    flex-basis: 60%;
    padding-top: 0;
    font-size: 1.2rem;
  }
}

@media (max-width: 720px) {
  .projects-section-right {
    padding: 0;
    position: static;
  }
}

.projects-title {
  height: 100px;
}

@media (max-width: 720px) {
  .projects-title {
    height: 80px;
  }
}

.projects-intro {
  margin-bottom: 20px;
}

.display {
  width: 100%;
  align-self: flex-end;
  margin-top: 0;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  box-shadow: 5px 5px 10px #000;
}

.display-prev, .display-next {
  z-index: 4;
  color: #555;
  text-align: center;
  background-color: #222;
  padding-top: calc(38% - 4rem);
  font-size: 4rem;
  position: absolute;
  inset: 0 0 0 100%;
  overflow: hidden;
}

.display-prev .big, .display-next .big {
  color: #333;
  font-size: 7rem;
  position: absolute;
  top: 33%;
  left: 70%;
}

.display.slide-next .display-next {
  animation: 1.2s linear slide;
}

.display.slide-next .display-next .big {
  animation: 1.2s linear back-move;
}

.display.slide-prev .display-prev {
  animation: 1.2s linear slide-back;
}

.display.slide-prev .display-prev .big {
  animation: 1.2s linear reverse back-move;
}

.display-links {
  height: 100%;
  width: 100%;
  z-index: 3;
  background-color: #dd1313cc;
  justify-content: space-evenly;
  align-items: center;
  transition: bottom 1s;
  display: flex;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.display-image {
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.display:hover .links {
  height: 100%;
  bottom: 0;
}

.display-icon-wrapper {
  color: #fff;
  width: 50px;
  height: 50px;
  color: #fff;
  border: 3px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 46px;
  text-decoration: none;
  display: flex;
}

.display-icon-wrapper:hover {
  color: #111;
  border-color: #111;
}

.project {
  min-height: 55vh;
  flex-wrap: wrap;
  flex-basis: 60%;
  padding: 20px 40px;
  display: flex;
  position: relative;
  box-shadow: 6px 6px 6px #000000b3, 0 0 30px #00000080, 0 0 45px #0003;
}

@media (max-width: 1020px) {
  .project {
    padding: 10px 30px;
  }
}

@media (max-width: 720px) {
  .project {
    flex-basis: 100%;
    margin: 20px 0 0;
  }
}

.project-btns {
  position: absolute;
  top: 0;
  right: 0;
}

.project-btn {
  color: #ddd;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #111;
  border: 3px solid #ddd;
  border-radius: 50%;
  outline: none;
  margin: 20px 20px 20px 0;
  font-size: 2rem;
  line-height: 44px;
}

@media (max-width: 1020px) {
  .project-btn {
    width: 40px;
    height: 40px;
    margin: 10px 10px 10px 0;
    font-size: 1.5rem;
    line-height: 36px;
  }
}

.project-btn:first-child {
  padding-right: 3px;
}

.project-btn:last-child {
  padding-left: 3px;
}

.project-btn:hover {
  color: #dd1313;
  border-color: #dd1313;
}

.project-title {
  opacity: 1;
  word-wrap: break-word;
  flex-basis: 100%;
  padding: 20px 0 0;
  font-size: 2.5rem;
  transition: all .3s;
}

@media (max-width: 720px) {
  .project-title {
    padding-top: 40px;
  }
}

.project-title-cursor {
  margin-left: 1px;
  animation: 1s infinite blink;
  display: inline;
  position: relative;
  bottom: 3px;
}

.project-title-cursor.hidden {
  display: none;
}

.project-tech {
  color: #888;
  flex-basis: 100%;
  margin: 10px 0;
  font-style: italic;
  transition: all .6s;
}

@media (max-width: 1020px) {
  .project-tech {
    margin-bottom: 30px;
  }
}

@media (max-width: 720px) {
  .project-tech {
    margin-bottom: 10px;
  }
}

.project-desc {
  opacity: 1;
  flex-basis: 100%;
  margin-bottom: 10px;
  transition: all .6s .2s;
}

@media (max-width: 1020px) {
  .project-desc {
    margin-bottom: 20px;
  }
}

@media (max-width: 512px) {
  .project-desc {
    font-size: 1.3rem;
  }
}

.project-desc-tech {
  flex-basis: 75%;
  transition: all .6s .2s;
}

@media (max-width: 512px) {
  .project-desc-tech {
    display: none;
  }
}

.project-scroll {
  flex-basis: 100%;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

@media (max-width: 720px) {
  .project-scroll {
    margin-right: auto;
    padding-top: 10px;
  }
}

.project-scroller {
  height: 4px;
  background-color: #888;
  border-radius: 2px;
  flex-grow: 1;
  margin: 0 20px;
  position: relative;
}

.project-suffix {
  width: 10%;
  height: 6px;
  background-color: #111;
  border: 1px solid #888;
  border-radius: 3px;
  transition: left .5s;
  position: absolute;
  bottom: -1px;
  left: 0%;
}

.project-link-all {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 720px) {
  .project-link-all {
    top: 110px;
    bottom: auto;
    right: 10%;
  }
}

.project-icon-wrapper {
  color: #fff;
  width: 50px;
  height: 50px;
  color: #fff;
  border: 3px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 46px;
  text-decoration: none;
  display: flex;
}

.project-icon-wrapper:hover {
  color: #111;
  border-color: #111;
}

.hidden {
  opacity: 0;
  transform: translateX(20px);
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.contact {
  font-size: 2rem;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .contact {
    min-height: 75vh;
  }
}

@media (max-width: 512px) {
  .contact {
    font-size: 1.4rem;
  }
}

.contact-title {
  text-align: center;
}

.contact-subtitle {
  text-align: center;
  margin-bottom: 10px;
}

@media (max-width: 512px) {
  .contact-subtitle {
    font-size: 1.4rem;
  }
}

.contact-hi {
  font-style: italic;
  font-weight: 700;
}

.form {
  width: 60vw;
  margin: 0 auto;
  font-size: 0;
}

@media (max-width: 512px) {
  .form {
    width: 80vw;
  }
}

.form-feedback {
  width: 100%;
  height: 30px;
  color: none;
  text-align: center;
  opacity: 0;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 26px;
}

.form-feedback.red {
  color: #dd1313;
  background-color: #dd13131a;
  border: 2px solid #dd131380;
  animation: 6s forwards fadeout;
}

.form-feedback.green {
  color: green;
  background-color: #00ff001a;
  border: 2px solid #00ff0080;
  animation: 6s forwards fadeout;
}

.form-field {
  margin-bottom: 25px;
  position: relative;
}

.form-field-half {
  width: calc(50% - 10px);
  display: inline-block;
}

@media (max-width: 720px) {
  .form-field-half {
    width: 100%;
    display: block;
  }
}

.form-field-message {
  width: auto;
  height: auto;
  display: inline-block;
}

.form-field-message:after {
  bottom: 5px;
}

.form-field-btn:after {
  height: 0;
  background-color: #0000;
}

.form-field:first-of-type {
  margin-right: 20px;
}

@media (max-width: 512px) {
  .form-field:first-of-type {
    margin-right: 0;
  }
}

.form-field:last-child {
  text-align: center;
}

.form-field:last-child:after {
  content: none;
}

.form-field:after {
  content: "";
  width: 0;
  height: 4px;
  transition: all .5s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.form-field:focus-within .form-label {
  color: #dd1313;
}

.form-field:focus-within:after {
  width: 100%;
  background-color: #dd1313;
}

.form-field:focus-within.valid .form-label {
  color: green;
}

.form-field:focus-within.valid:after {
  background-color: green;
}

.form-label {
  z-index: 1;
  text-transform: uppercase;
  color: #555;
  font-size: 1.5rem;
  transition: all .5s;
  position: absolute;
  top: 5px;
  left: 10px;
}

@media (max-width: 512px) {
  .form-label {
    font-size: 1.1rem;
  }
}

.form-input {
  height: 70px;
  width: 100%;
  color: #ddd;
  background-color: #222;
  padding: 20px 10px 0;
  font-size: 2.2rem;
}

@media (max-width: 512px) {
  .form-input {
    height: 50px;
    padding-top: 15px;
    font-size: 1.4rem;
  }
}

.form-input-area {
  height: 250px;
  width: 60vw;
  padding-top: 30px;
}

@media (max-width: 512px) {
  .form-input-area {
    width: 80vw;
  }
}

.form-btn {
  color: #111;
  letter-spacing: 1px;
  cursor: not-allowed;
  background-color: #ddd;
  border-radius: 20px;
  margin: 20px auto;
  padding: 15px 60px;
  font-size: 1.6rem;
  font-weight: 600;
  transition: background-color .5s;
}

.form-btn-wrapper {
  display: inline-block;
}

.form-btn:hover {
  background-color: #dd1313;
  box-shadow: 5px 5px 5px #000;
}

.form-btn.valid {
  cursor: pointer;
}

.form-btn.valid:hover {
  background-color: green;
}

.footer {
  background-color: #000;
  padding: 20px 10%;
  display: flex;
  position: relative;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .footer {
    flex-wrap: wrap;
  }
}

@media (max-width: 1020px) {
  .footer {
    flex-wrap: wrap;
  }
}

.footer a {
  color: #888;
  margin-top: 15px;
  text-decoration: none;
  display: inline-block;
}

.footer a:hover {
  color: #dd1313;
}

.footer ul {
  list-style: none;
}

.footer-pic {
  width: 140%;
  height: 140%;
  opacity: .2;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(20deg);
}

@media (max-width: 512px) {
  .footer-pic {
    top: auto;
    bottom: 0;
    overflow: visible;
  }
}

.footer-pic-wrapper {
  height: 200px;
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 512px) {
  .footer-pic-wrapper {
    top: 0;
    bottom: auto;
  }
}

.footer-title {
  margin: 10px 0;
}

.footer-title-social {
  padding-right: 20px;
}

@media (max-width: 512px) {
  .footer-title-project {
    margin-top: 10px;
  }
}

@media (max-width: 1020px) {
  .footer-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 512px) {
  .footer-title {
    text-align: center;
    padding: 0;
  }
}

.footer-section {
  flex-basis: 25%;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .footer-section {
    flex-basis: auto;
  }
}

@media (max-width: 1020px) {
  .footer-section {
    flex-basis: auto;
    font-size: .9rem;
  }
}

@media (max-width: 512px) {
  .footer-section {
    flex-basis: 100%;
    font-size: 1.2rem;
  }
}

.footer-section:first-child {
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .footer-section:first-child {
    position: absolute;
    top: 10px;
    right: 10%;
  }
}

@media (max-width: 1020px) {
  .footer-section:first-child {
    position: absolute;
    top: 10px;
    right: 10%;
  }
}

@media (max-width: 512px) {
  .footer-section:first-child {
    position: static;
  }
}

.footer-section-wide {
  max-width: 100vw;
  flex-wrap: wrap;
  flex-basis: 50%;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .footer-section-wide {
    flex-grow: 1;
  }
}

@media (max-width: 1020px) {
  .footer-section-wide {
    flex-grow: 1;
  }
}

@media (max-width: 512px) {
  .footer-section-wide {
    flex-direction: column;
  }
}

.footer-section-wide h3 {
  flex-basis: 100%;
}

.footer-logo {
  margin-bottom: 20px;
  font-size: 3rem;
  display: inline-block;
  position: relative;
}

.footer-logo .copy {
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: -2rem;
}

@media (max-width: 1020px) {
  .footer-logo .copy {
    font-size: 1rem;
    right: -1rem;
  }
}

@media (min-width: 1021px) and (orientation: portrait) {
  .footer-logo {
    margin-left: auto;
    font-size: 2.5rem;
  }
}

@media (max-width: 1020px) {
  .footer-logo {
    margin-left: auto;
    font-size: 2.5rem;
  }
}

@media (max-width: 512px) {
  .footer-logo {
    margin-right: auto;
  }
}

h3 + .footer-social-field {
  padding-right: 10px;
}

.footer-social-name {
  word-wrap: break-word;
}

@media (min-width: 1021px) and (orientation: portrait) {
  .footer-social-name.email {
    margin-right: 10px;
  }
}

.footer-social-icon {
  width: 30px;
  display: inline-block;
}

.footer-social-link.disabled {
  pointer-events: none;
  cursor: default;
}

.footer-project-item.in-progress a {
  color: #222;
}

/*# sourceMappingURL=index.39a26ed6.css.map */
